<template>
  <div class="table-column">
    <BaseLink
      v-if="isVisible"
      icon-right="angle-right"
      :to="{ name: 'match', params: { id: match.id } }"
    >
      {{ $t('global.more') }}
    </BaseLink>
  </div>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'CellMatchLink',

  components: {
    BaseLink,
  },

  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    match: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('tournaments', ['getTournament']),

    isTffa() {
      return (
        this.getTournament(this.tournamentId)?.system?.code ===
        'team_ffa'
      );
    },

    isVisible() {
      if (this.isTffa && this.match.id) {
        return true;
      }
      const teamsHash = [
        this.match.team1?.hash,
        this.match?.team2?.hash,
      ];
      if (teamsHash.filter(Boolean)?.length === 2) {
        return !teamsHash.includes(this.freeSlot.hash);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-column {
  @include min-laptop() {
    justify-content: flex-end;
  }
}
</style>
