<template>
  <div>
    <div class="content-bg">
      <h2 class="title">{{ $t('tournaments.settings_bracket') }}</h2>

      <div v-if="isTabsVisible" :key="$route.name" class="tabs">
        <pvp-btn
          variant="clear"
          :to="{ name: 'tournament-edit-grid', params }"
          class="tabs-btn"
        >
          {{ $t('global.settings') }}
        </pvp-btn>
        <pvp-btn
          :to="{ name: 'tournament-edit-grid-settings', params }"
          variant="clear"
          class="tabs-btn"
        >
          {{ $t('tournaments.schedule') }}
        </pvp-btn>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    ...mapState('tournamentSettings', ['form']),
    ...mapState('tournaments', ['availableSystems']),

    params() {
      const { tnId, orgId } = this.$route?.params || {};
      return {
        tnId: Number(tnId),
        orgId: Number(orgId),
      };
    },

    isTabsVisible() {
      const { code } =
        this.availableSystems[this.form.idSystem?.value] || {};
      return code !== 'rating';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.title {
  @include min-laptop() {
    padding: 42px 48px 0;
  }
  @include max-laptop() {
    padding: 20px 12px 0;
  }
}

.content-bg {
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  margin-bottom: 12px;
}

.tabs {
  margin-top: 20px;
  border-bottom: 1px solid rgba(white, 0.1);
  @include min-laptop() {
    padding-left: 48px;
    padding-right: 48px;
  }

  @include max-laptop() {
    padding-left: 12px;
    padding-right: 12px;
  }

  &-btn {
    &,
    &:focus {
      font-size: 18px;
      padding-bottom: 10px;
      border-bottom: 5px solid rgba(white, 0);
      color: rgba(white, 0.7);
    }

    &.router-link-exact-active {
      border-bottom-color: $azure;
      color: white;
    }

    & + & {
      margin-left: 36px;
    }
  }
}
</style>
