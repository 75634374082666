<template>
  <div class="settings">
    <div class="content-bg">
      <div class="buttons padded">
        <pvp-btn
          v-if="tournament?.isBracketFormed"
          :disabled="!abilities.canBracketClear"
          @click="createBracket(false)"
        >
          {{ $t('tournaments.deleteBracket') }}
        </pvp-btn>
        <pvp-btn
          v-else
          :disabled="!abilities.canBracketForm"
          @click="createBracket(true)"
        >
          {{ $t('tournaments.state_to_bracket-create_action') }}
        </pvp-btn>

        <pvp-btn
          v-if="tournament?.isBracketPublished"
          :disabled="!abilities.canBracketUnPublish"
          @click="publishBracket(false)"
        >
          {{ $t('matches.edit_unpublish') }}
        </pvp-btn>
        <pvp-btn
          v-else
          :disabled="!abilities.canBracketPublish"
          @click="publishBracket(true)"
        >
          {{ $t('tournaments.state_to_bracket-publish_action') }}
        </pvp-btn>
        <FinishRoundButton
          v-if="system === 'swiss'"
          @done="handleFinishRound"
        />
        <p
          v-for="(message, index) in errorMessages"
          :key="index"
          class="error"
        >
          {{ message }}
        </p>
      </div>

      <template v-if="canEdit">
        <!-- в рамках MVP швейцарской системы скрываем настройки -->
        <template v-if="system !== 'swiss'">
          <BaseForm
            v-if="canChangeAll"
            class="padded form"
            @submit="editMatches"
          >
            <h4>{{ $t('matches.change_all') }}</h4>

            <BaseSelect
              v-model="roundId"
              :label="$t('tournaments.settings_round')"
              :placeholder="$t('round.select')"
              :options="roundsList"
              :disabled="roundsList.length === 0"
              value-key="id"
              label-key="name"
              class="indent round"
              @input="skip(['fromId', 'toId', 'date'])"
            />

            <div class="inline indent">
              <BaseSelect
                v-model="fromId"
                :label="$t('matches.from')"
                :placeholder="`${$t('matches.single')} 1`"
                :disabled="!roundId || !matchesFromList.length > 0"
                :error-message="errors.minMatchIndex"
                :options="matchesFromList"
                class="indent"
                @input="skip(['toId', 'date'])"
              >
                <template #selected-option="{ option }">
                  {{ $t('matches.single') }} {{ option }}
                </template>
                <template #option="{ option }">
                  {{ $t('matches.single') }} {{ option }}
                </template>
              </BaseSelect>

              <BaseSelect
                v-model="toId"
                :label="$t('matches.to')"
                :placeholder="`${$t('matches.single')} 2`"
                :disabled="!fromId || !matchesToList.length > 0"
                :error-message="errors.maxMatchIndex"
                :options="matchesToList"
                class="indent"
                @input="skip(['date'])"
              >
                <template #selected-option="{ option }">
                  {{ $t('matches.single') }} {{ option }}
                </template>
                <template #option="{ option }">
                  {{ $t('matches.single') }} {{ option }}
                </template>
              </BaseSelect>

              <pvp-datepicker
                v-model="date"
                :label="$t('date.dateandtime')"
                :placeholder="$t('date.select_dateandtime')"
                :error-message="errors.matchesStartTime"
                :disabled="!fromId || !toId"
                :has-time="true"
                position="bottom"
                @update:error-message="errors.matchesStartTime = ''"
              />
            </div>

            <pvp-btn
              type="submit"
              :disabled="!date"
              :is-loading="isLoading"
            >
              {{ $t('global.apply') }}
            </pvp-btn>

            <InfoBox v-if="info.text" :variant="info.variant">
              {{ info.text }}
            </InfoBox>
          </BaseForm>
        </template>
      </template>
      <div v-else class="empty">
        <Icon name="pvp-swords" :size="64" />
        <p class="text">
          {{ $t('tournaments.settings_bracketEditError') }}
        </p>
      </div>
    </div>

    <BracketMatchSettings />
  </div>
</template>

<script>
import BracketMatchSettings from '@components/TournamentComponents/Settings/Bracket/BracketMatchSettings';
import FinishRoundButton from './FinishRoundButton';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'BracketScheduleSettings',
  components: {
    BracketMatchSettings,
    FinishRoundButton,
    BaseSelect,
    Icon,
    BaseForm,
    InfoBox,
  },
  data: () => ({
    isLoading: false,
    errorMessages: [],
    date: null,
    roundId: null,
    fromId: null,
    toId: null,
    errors: {},
    info: {},
    now: Date.now() / 1000,
  }),
  computed: {
    ...mapState('tournamentSettings', ['abilities']),
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),

    orgId() {
      return this.$route.params.orgId;
    },

    tnId() {
      return this.$route.params.tnId;
    },

    tournament() {
      return this.getTournament(this.$route.params.tnId);
    },

    canEdit() {
      const status = this.tournament?.status?.code;
      return (
        ['executing', 'preparation', 'finished'].includes(status) &&
        this.tournament?.isBracketFormed
      );
    },

    system() {
      return this.tournament?.system?.code;
    },

    canChangeAll() {
      return !['rating', 'subgroups'].includes(this.system);
    },

    hasRoundEditRestictions() {
      return ['executing', 'cancelled', 'finished'].includes(
        this.tournament?.status?.code,
      );
    },

    rounds() {
      const rounds = this.getTournamentBracket(this.tnId);
      if (this.system === 'double_elim') {
        const result = [];
        if (rounds?.winners) {
          result.push(
            {
              name: this.$t('tournaments.scheduleTable_topBracket'),
              disabled: true,
            },
            ...rounds.winners,
          );
        }
        if (rounds?.losers) {
          result.push(
            {
              name: this.$t(
                'tournaments.scheduleTable_bottomBracket',
              ),
              disabled: true,
            },
            ...rounds.losers,
          );
        }
        return result;
      }
      if (this.system === 'subgroups') {
        return rounds?.detail || [];
      }
      return rounds;
    },

    roundsList() {
      return (
        this.rounds?.reduce(
          (result, { id, name, disabled, tsStartTime }) => {
            if (this.hasRoundEditRestictions) {
              if (disabled || tsStartTime > this.now) {
                result.push({ id, name, disabled });
              }
            } else {
              result.push({ id, name, disabled });
            }
            return result;
          },
          [],
        ) || []
      );
    },

    matchesFromList() {
      const round = this.rounds?.find(
        ({ id }) => id === this.roundId,
      );
      return (
        round?.matches?.map(({ numPlaceRound }) => numPlaceRound) ||
        []
      );
    },

    matchesToList() {
      if (this.matchesFromList.length === 1) {
        return this.matchesFromList;
      }
      return this.matchesFromList.slice(this.fromId || 0);
    },
  },

  methods: {
    ...mapActions('tournaments', [
      'fetchTournamentPage',
      'fetchTournamentBracket',
    ]),
    ...mapActions('tournamentSettings', [
      'fetchTournamentAbilities',
      'fetchTournamentWorkflow',
    ]),
    skip(list) {
      list.forEach((item) => {
        this[item] = null;
      });
    },

    editMatches() {
      this.errors = {};
      this.info = {};
      this.isLoading = true;
      return api
        .post(`/round/${this.roundId}/editmatches`, {
          form: {
            minMatchIndex: this.fromId,
            maxMatchIndex: this.toId,
            matchesStartTime: this.date,
          },
        })
        .then((data) => {
          if (data.success) {
            this.info = {
              variant: 'success',
              text: this.$t('save.success'),
            };
            setTimeout(() => {
              this.info = {};
            }, 3000);
            return this.fetchTournamentBracket(
              this.$route.params.tnId,
            );
          }
          return false;
        })
        .catch(({ error }) => {
          this.errors = error;
          if (error?.common) {
            this.info = {
              variant: 'error',
              text: error.common?.join(', '),
            };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createBracket(needCreate) {
      if (needCreate) {
        api
          .patch(
            `/organization/${this.orgId}/tournament/${this.tnId}/bracketcreate`,
          )
          .then(this.updateTournamentData)
          .catch(
            (error) =>
              (this.errorMessages = JSON.parse(error.message)),
          );
      } else {
        api
          .delete(
            `/organization/${this.orgId}/tournament/${this.tnId}/bracketdelete`,
          )
          .then(this.updateTournamentData)
          .catch(
            (error) =>
              (this.errorMessages = JSON.parse(error.message)),
          );
      }
    },

    publishBracket(needPublish) {
      if (needPublish) {
        api
          .patch(
            `/organization/${this.orgId}/tournament/${this.tnId}/bracketpublish`,
          )
          .then(this.updateTournamentData);
      } else {
        api
          .patch(
            `/organization/${this.orgId}/tournament/${this.tnId}/bracketunpublish`,
          )
          .then(this.updateTournamentData);
      }
    },

    updateTournamentData() {
      this.fetchTournamentAbilities(this.tnId);
      this.fetchTournamentPage(this.tnId);
      this.fetchTournamentWorkflow(this.tnId);
    },

    handleFinishRound() {
      this.fetchTournamentBracket(this.$route.params.tnId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.content-bg {
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
}
.indent {
  margin-top: 20px;
}

.padded {
  @include min-laptop() {
    padding: 20px 48px;
  }
  @include max-laptop() {
    padding: 20px 12px;
  }
}

.buttons {
  border-bottom: 1px solid rgba(white, 0.1);
  @include max-tablet() {
    text-align: center;
  }

  .button {
    @include max-tablet() {
      margin: 8px 8px 0;
    }

    & + .button {
      @include min-tablet() {
        margin-left: 14px;
      }
    }
  }
}

.select.round {
  &,
  ::v-deep .label {
    max-width: 100%;
    width: 430px;
  }
}

.inline {
  display: flex;
  justify-content: space-between;
  max-width: 430px;

  .select {
    margin-top: 0;
    width: 110px;

    &.pvp-datepicker {
      flex-grow: 1;
    }

    & + .select {
      margin-left: 14px;
    }
  }
}

.button {
  width: 160px;
}

.form {
  .button {
    margin-top: 32px;
  }
}

.pvp-info-box {
  margin-top: 12px;
}

.empty {
  color: #8d8d8d;
  text-align: center;

  .icon {
    font-size: 80px;
    margin: 50px auto 0;
  }

  .text {
    margin-top: 20px;
  }
}

.error {
  padding-top: 12px;
  font-weight: bold;
  color: var(--additional-color-red);
}
</style>
